import emitter from "mitt";
import type { WhitelistTier } from "~/services/types";

type Events = {
  "connect-wallet:open": void;
  "connect-wallet:close": void;
  "whitelist-dialog:open": void;
  "whitelist-dialog:close": void;
  "whitelist:purchase-tier": WhitelistTier;
  "referral-history-dialog:open": void;
  "referral-history-dialog:close": void;
};

export const mitt = emitter<Events>();

type ApiEvents = {
  "error:purchase-price": string;
};
export const api = emitter<ApiEvents>();
